/**
 * @name: 采购管理-采购订单管理接口文件
 * @author:lili
 * @date: 2023-08-04 17:46
 * @description： 采购管理-采购订单管理接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ, postW} from "@/request"
import {IPageRes} from "@/apis/page";
import {IOrder, IOrderAdd, IOrderGate, IOrderParams} from "@/apis/purchase/order/types";
/**
 * 查询采购订单管理列表
 * @param params 查询参数
 * @returns
 */
export const orderQueryApi = (params: IOrderParams) => get("/admin/purchase/order/query", params)

/**
 * 创建采购订单
 * @param data
 * @returns
 */
export const orderCreateApi = (data: IOrderAdd) => postJ("/admin/purchase/order/create", data)
/**
 * 编辑采购订单
 * @param data
 * @returns
 */
export const orderModifyApi = (data: IOrderAdd) => postJ("/admin/purchase/order/modify", data)
/**
 * 修改结算金额
 * @param data
 * @returns
 */
export const orderModifySettlementAmountApi = (data: any) => postJ("/admin/purchase/order/modifySettlementAmount", data)
/**
 * 批量提交
 * @param data
 * @returns
 */
export const orderBatchSubmissionApi = (data:any) => postJ("/admin/purchase/order/batchSubmission", data)


/**
 * 核对采购订单
 * @param data
 * @returns
 */
export const orderCheckApi = (data: IOrderGate[]) => postJ("/admin/purchase/order/check", data)
/**
 * 获取采购订单详情
 * @param id
 * @returns
 */
export const orderDetailApi = (id: string) => get<IOrder>("/admin/purchase/order/detail/" + id)

/**
 * 采购员下拉列表
 * @returns
 */
export const personnelListApi = () => get("/admin/purchase/order/personnelList")

/**
 * 供应商下拉列表
 * @returns
 */
export const supplierListApi = () => get("/admin/purchase/order/supplierList")
