
/**
 * @name: 采购管理-采购订单管理
 * @author:lili
 * @date: 2023-08-04 17:53
 * @description： 采购管理-采购订单管理
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {IOrder, IOrderAdd, IOrderGate, IOrderParams, IOrderProductDtoList} from "@/apis/purchase/order/types";
import {
  orderBatchSubmissionApi,
  orderCheckApi,
  orderCreateApi,
  orderDetailApi,
  orderModifyApi,
  orderModifySettlementAmountApi,
  orderQueryApi,
  personnelListApi,
  supplierListApi
} from "@/apis/purchase/order";
import {deepCopy} from "@/utils/common";
import {gateGetGateListApi} from "@/apis/gate/info";
import {productGetListApi} from "@/apis/product/info";
import Big from "big.js"

@Component({})
export default class purchaseOrder extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: [] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IOrderParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IOrder> = {
    settlementPrice: '0.00'
  }
  //修改结算价格
  settlementDialog: boolean = false;
  //核对采购订单
  checkDialog: boolean = false;
  rulesPrice = (rule: any, value: any, callback: any) => {
    if (value === null || value === "" || value === undefined) {
      return callback(new Error('0.01~999999.99!'))
    }
    if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value)) {
      return callback(new Error('0.01~999999.99!'))
    } else if (parseFloat(value) <= 0 || value > 999999.99) {
      return callback(new Error('0.01~999999.99!'))
    }
    callback()
  }
  rules = {
    productPrice: [
      {required: true, trigger: 'blur', validator: this.rulesPrice}
    ],
    purchasePrice: [
      {required: true, trigger: 'blur', validator: this.rulesPrice}
    ],
    gateNumber: [
      {required: true, trigger: 'blur', validator: (rule: any, value: any, callback: any) => {
          if (value === null || value === "" || value === undefined) {
            return callback(new Error('0~999999.99!'))
          }
          if (!/^\D*([0-9]\d*\.?\d{0,2})?.*$/.test(value)) {
            return callback(new Error('0~999999.99!'))
          } else if (parseFloat(value) <0 || value > 999999.99) {
            return callback(new Error('0~999999.99!'))
          }
          callback()
        }}
    ],
    settlementPrice: [
      {
        required: true, trigger: 'blur', validator: (rule: any, value: any, callback: any) => {
          if (!value || value == '') {
            return callback(new Error('请输入结算金额!'))
          }else if(!/^\D*([0-9]\d*\.?\d{0,2})?.*$/.test(value)){
            return callback(new Error('金额限0~999999.99!!'))
          } else if (parseFloat(value) < 0 || value > 999999.99) {
            return callback(new Error('金额限0~999999.99!!'))
          }
          callback()
        }
      }
    ],
  }
  //顶部合计数据
  pageData: any = {
    settlementPrice: 0, //结算金额
    orderPrice: 0,//订单金额
  }
  //是否是 核对
  isCheck:boolean=false
  //选中的行
  selectRow: any = []
  //档口下拉列表
  gateList: any = []
  //供应商下拉列表
  supplierList: any = []
  //采购员下拉列表
  personnelList: any = []
  //商品列表
  productList: any = []
  //采购明细
  tableForm: any = {
    orderProductData: []
  }
  //选中的商品id
  selectProductId: string = ''
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: true,
    labelWidth: '100px',
    dialogWidth: '1500px',
    editTitle:'编辑采购订单',
    viewTitle:'采购订单详情',
    addTitle:'新增采购订单',
    menu: true,
    column: [
      {
        label: "",
        type: "selection",
        width: 50,
        align: "center",
        selectable: this.rowSelectble,
        viewHide: true,
        addHide: true,
        editHide: true
      },
      {
        label: "采购订单编号",
        prop: "purchaseOrderSn",
        align: "center",
        width: 180,
        search: true,
        addHide: true,
        editHide: true,
        slot: true,
        span:12
      },
      {
        label: "采购商品种类",
        prop: "productQty",
        width: 100,
        align: "center",
        addHide: true,
        editHide: true,
        viewHide:true,
        span:12
      },
      {
        label: "采购单金额",
        prop: "orderPrice",
        align: "center",
        search: true,
        addHide: true,
        editHide: true,
        span:12
      },
      {
        label: "结算金额",
        prop: "settlementPrice",
        align: "center",
        search: true,
        slot: true,
        addHide:true,
        editHide:true,
        span:12
      },
      {
        label: "优惠金额",
        prop: "discountPrice",
        align: "center",
        help: "采购单金额 - 结算金额",
        width: 100,
        slot: true,
        addHide: true,
        editHide: true,
        viewSlot: true,
        span:12
      },
      {
        label: "采购员",
        prop: "personnelName",
        align: "center",
        search: true,
        addHide: true,
        editHide: true,
        width: 150,
        span: 12
      },
      {
        label: "采购员",
        prop: "personnelId",
        align: "center",
        hide: true,
        viewHide: true,
        type: "select",
        dicData: [],
        editSlot: true,
        addSlot: true,
        rules: [
          {required: true, message: "请选择采购员", trigger: "blur"},
        ],
        span: 6
      },
      {
        label: "供应商",
        prop: "supplierName",
        align: "center",
        search: true,
        addHide: true,
        editHide: true,
        width: 150,
        span: 12
      },
      {
        label: "供应商",
        prop: "supplierId",
        align: "center",
        hide: true,
        viewHide: true,
        type: "select",
        editSlot: true,
        addSlot: true,
        dicData: [],
        rules: [
          {required: true, message: "请选择供应商", trigger: "blur"},
        ],
        span: 6
      },
      {
        label: "商品名称",
        prop: "productName",
        search: true,
        hide: true,
        viewHide: true,
        addHide: true,
        editHide: true,
        align: "center",
      },
      {
        label: "是否异常单",
        prop: "isAbnormal",
        align: 'center',
        search: true,
        addHide: true,
        editHide: true,
        //@ts-ignore
        default: '--',
        type: "select",
        dicData: [{label: '是', value: true}, {label: '否', value: false}],
        span: 12
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        type: "select",//	状态:1=草稿 2=待核对 3=已核对
        dicData: [{label: '草稿', value: 1}, {label: '待核对', value: 2}, {label: '已核对', value: 3}],
        addHide: true,
        editHide: true,
        search: true,
        span:12
      },
      {
        label: "采购日期",
        prop: "purchaseTime",
        align: "center",
        width: 150,
        search: true,
        searchSlot: true,
        addSlot: true,
        editSlot: true,
        rules: [
          {required: true, message: "请选择采购日期", trigger: "blur"},
        ],
        span: 12
      },
      {
        label: "结算金额",
        prop: "settlementPrice",
        align: "center",
        hide: true,
        viewHide: true,
        editSlot: true,
        rules: this.rules.settlementPrice,
        span: 6
      },
      {
        label: "创建时间",
        prop: "addTime",
        align: "center",
        type: "daterange",
        width: 180,
        //@ts-ignore
        default: '--',
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
        search: true,
        addHide: true,
        editHide: true,
        span: 12
      },
      {
        label: "经办人",
        prop: "addName",
        //@ts-ignore
        default: '--',
        align: "center",
        addHide: true,
        editHide: true,
        span: 12
      },
      {
        label: "核单员",
        prop: "checkName",
        //@ts-ignore
        default: '--',
        hide: true,
        addHide: true,
        editHide: true,
        span: 12
      },
      {
        label: "备注",
        prop: "remarks",
        align: "center",
        type: 'textarea',
        //@ts-ignore
        default: '无',
        hide: true,
        editSlot: true,
        maxlength: 200,
        showWordLimit: true,
        span: 24
      },
      {
        label: "采购明细",
        prop: "orderProductDtoList",
        align: "center",
        hide: true,
        addSlot: true,
        editSlot: true,
        viewHide: true,
        span: 24
      },
    ]
  }

  //计算 采购明细 -商品金额合计
  get amountTotal() {
    let sum: number = 0;
    if (this.tableForm.orderProductData && this.tableForm.orderProductData.length > 0) {
      for (let i = 0; i < this.tableForm.orderProductData.length; i++) {
        sum = new Big(this.tableForm.orderProductData[i]["purchasePrice"] || 0).times(this.tableForm.orderProductData[i]["purchaseQty"] || 0).plus(sum).toNumber();
      }
    }
    return sum.toFixed(2)
  }

  //计算 采购批量选择列的id
  get selectIds() {
    return this.selectRow.map((item: any) => item.id)
  }

  /**
   * 行过虑 false禁用选择
   * @param row
   * 状态:1=草稿 2=待核对 3=已核对
   */
  rowSelectble(row: any, index: number) {
    if (row.status == 1) {
      return true
    }
    return false
  }

  /**
   * 修改结算金额-弹窗
   * @param row
   */
  handleSettlementPrice(row: IOrder) {
    this.modelForm.id = row.id;
    this.modelForm.settlementPrice = row.settlementPrice || '0.00';
    this.settlementDialog = true;
  }

  /**
   * 结算金额-提交
   * @param row
   */
  handleSettlementAffirm() {
    // @ts-ignore
    this.$refs['settlementRef'].validate((valid) => {
      if (valid) {
        orderModifySettlementAmountApi(this.modelForm).then(e => {
          if (e) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.getList();
            this.settlementDialog = false;
          }
        })
      }
    })
  }

  /**
   * 批量提交
   */
  handleSubmitBatch() {
    if (this.selectIds.length == 0) {
      return this.$message.error("请选择数据")
    }
    this.orderBatch(this.selectIds);
  }

  /**
   * 单个 提交采购订单
   * @param row
   */
  handleSubmit(row: any, index: number) {
    this.orderBatch([row.id]);
  }

  /**
   * 提交采购订单-api
   * @param row
   */
  orderBatch(data: any[]) {
    this.$confirm('是否确认提交该采购订单?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      orderBatchSubmissionApi({ids:data}).then(e => {
        if (e) {
          this.$message({
            type: 'success',
            message: '操作成功!'
          });
          this.getList();
        }
      })
    }).catch(() => {
      this.selectRow = []
    });
  }

  /**
   * 新增
   * @param row
   */
  handleAdd() {
    this.isCheck = false;
    this.tableForm.orderProductData = [];
    this.modelForm.settlementPrice='0.00';
    this.getGateList();
    this.getProductGetList();
    this.getSupplierList();
    this.getPersonnelList();
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
  }

  /**
   * 修改
   * @param row
   */
  async handleEdit(row: IOrder, index: number) {
    this.isCheck = false;
    this.orderDetail(row, index)
  }

  /**
   * 核对
   * @param row
   */
  handleCheck(row: IOrder, index: number) {
    this.isCheck = true;
    this.checkDialog = true;
    this.orderDetail(row, index)
  }

  /**
   * 编辑/核对详情
   * @param row
   * @param index
   */
  orderDetail(row: IOrder, index: number) {
    this.tableForm.orderProductData = [];
    orderDetailApi(row.id as string).then(res => {
      if (res) {
        if (res.orderProductVoList) {
          this.getOrderProductData(res, false);
        }
        this.modelForm = res;
        if (!this.isCheck) {
          // @ts-ignore
          this.$refs.crudRef.rowEdit(res, index);
        }

      }
    })

  }

  /**
   * tableForm.orderProductData
   * @param IOrder
   */
  getOrderProductData(res: IOrder,type:boolean) {
    let orderProductDto: IOrderProductDtoList[] = [];
    this.gateList = res.orderProductVoList[0] ? res.orderProductVoList[0].purchaseOrderGateVos : [];
    res.orderProductVoList.forEach(e => {
      let obj: any = {
        productId: e.productId,
        purchasePrice: e.purchasePrice,
        purchaseUnit: e.purchaseUnit,
        remarks: e.remarks,
        productName: e.productName,
        batchNo: e.batchNo,
        decreaseQty: e.decreaseQty,
        purchaseQty: e.purchaseQty,
        checkQty: type?e.checkQty:e.purchaseQty,
        productPrice: e.productTotalPrice,
        purchaseOrderGateVos:e.purchaseOrderGateVos
      }
      for (let j = 0; j < e.purchaseOrderGateVos.length; j++) {
        obj['gateNumber' + this.gateList[j].id] = e.purchaseOrderGateVos[j].purchaseQty;
        if (this.isCheck) {
          obj['checkGateNumber' + this.gateList[j].id] =type? e.purchaseOrderGateVos[j].checkQty: e.purchaseOrderGateVos[j].purchaseQty;
        }
      }
      this.setProduct(e.productId, true)
      orderProductDto.push(obj)
    });
    this.tableForm.orderProductData = orderProductDto;

  }

  /**
   * 详情
   * @param row
   */
  openDetail(row: IOrder, index: number) {
    this.isCheck = true;
    orderDetailApi(row.id as string).then(e => {
      if (e) {
        if (e.orderProductVoList) {
          this.getOrderProductData(e,true);
        }
        // @ts-ignore
        this.$refs.crudRef.rowView(e, index)
      }
    })
  }

  /**
   * 取 采购明细 tableForm.orderProductData
   * 设置 form.orderProductDtoList
   */
  setOrderProductDto() {
    let orderProductDto: IOrderProductDtoList[] = [];
    this.tableForm.orderProductData.forEach((e: any) => {
      let gateDto: IOrderGate[] = [];
      for (let j = 0; j < this.gateList.length; j++) {
        let item: IOrderGate = {
          gateId:this.gateList[j].gateId?this.gateList[j].gateId:this.gateList[j].id,
          purchaseQty: e['gateNumber' + this.gateList[j].id]

        }
        gateDto.push(item)
      }
      let obj: IOrderProductDtoList = {
        productId: e.productId,
        purchasePrice: e.purchasePrice,
        purchaseUnit: e.purchaseUnit,
        remarks: e.remarks,
        orderGateDtoList: gateDto
      }
      orderProductDto.push(obj)
    })
    return orderProductDto;
  }

  /**
   * 新增提交
   */
  submitSave(form: IOrderAdd, done: any, loading: any) {
    // @ts-ignore
    this.$refs['tableForms'].validate((valid) => {
      if (valid) {
        form.orderProductDtoList = this.setOrderProductDto();
        orderCreateApi(form).then(e => {
          if (e) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.initData();
            done();
          }
        }).finally(() => {
          loading();
        })
      } else {
        loading();
      }
    })
  }

  /**
   * 编辑提交
   */
  submitEdit(form: IOrderAdd, done: any, loading: any) {
    // if(this.isCheck){
    //   this.submitCheck(form, done, loading);
    //   return
    // }
    // @ts-ignore
    this.$refs['tableForms'].validate((valid) => {
      if (valid) {
        form.orderProductDtoList = this.setOrderProductDto();
        // @ts-ignore
        delete form.orderProductVoList
        orderModifyApi(form).then(e => {
          if (e) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.initData();
            done();
          }
        }).finally(() => {
          loading();
        })
      } else {
        loading();
      }
    })
  }
  /**
   * 取 采购明细 tableForm.orderProductData
   * 设置 form.orderProductDtoList
   */
  setOrderOrderGate(id:string) {
    let orderGate: IOrderGate[] = [];
    this.tableForm.orderProductData.forEach((e: any) => {
      for (let j = 0; j < e.purchaseOrderGateVos.length; j++) {
        let obj: IOrderGate = {
          id: e.purchaseOrderGateVos[j].id,
          purchaseOrderProductId: e.purchaseOrderGateVos[j].purchaseOrderProductId,
          purchaseOrderId: e.purchaseOrderGateVos[j].purchaseOrderId,
          gateId: e.purchaseOrderGateVos[j].gateId,
          purchaseQty: e.purchaseOrderGateVos[j].purchaseQty,
          checkQty: e['checkGateNumber' + this.gateList[j].id],
        }
        orderGate.push(obj)
      }

    })
    return orderGate;
  }

  handleCheckAffirm() {
    this.submitCheck();
  }

  /***
   * 核对提交
   * @param form
   */
  submitCheck() {

    // @ts-ignore
    this.$refs['tableForms'].validate((valid) => {
      if (valid) {
        let orderGate: IOrderGate[] = this.setOrderOrderGate(this.modelForm.id || '');
        orderCheckApi(orderGate).then(e => {
          if (e) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.initData();
            this.checkDialog = false;
          }
        })
      }
    })
  }
  /**
   * 选择新增采购商品明细
   */
   handleSelect(e:any) {
    this.selectProductId = e;
  }
  /**
   * 新增采购商品明细
   */
  addOrderProductRow() {
     this.productList.forEach((item:any) => {
      if (item.id == this.selectProductId) {
        item.disabled = true;
        let obj: any = {
          productId: item.id,
          productName: item.productName,
          purchaseUnit: item.baseUnit,
          purchasePrice: '0.00',
          purchaseQty: '0.00',
          productPrice: '0.00',
          remarks: '',
        }
        this.selectProductId = '';
        for (let j = 0; j < this.gateList.length; j++) {
          obj['gateNumber' + this.gateList[j].id] = '0.00';
        }
        this.tableForm.orderProductData.push(obj);
      }
     });
  }

  /**
   * 删除采购商品明细
   */
  handleDelOrderProduct(row: any, index: number) {
    this.setProduct(row.productId, false)
    if (this.tableForm.orderProductData.length <= 1) {
      this.tableForm.orderProductData = [];
    } else {
      this.tableForm.orderProductData.splice(index, 1);
    }
  }


  /**
   * 档口input
   */
  inputGetePrice(row: any, i: number) {
    let qty: number = 0;
    for (let j = 0; j < this.gateList.length; j++) {
      if (this.isCheck)
        qty = new Big(row['checkGateNumber' + this.gateList[j].id] || 0).plus(qty).toNumber();
      else
        qty = new Big(row['gateNumber' + this.gateList[j].id] || 0).plus(qty).toNumber();
    }
    //不是对账
    if (!this.isCheck) {
      row.purchaseQty = qty
      if(qty==0){
        row.productPrice =0;
        return
      }
      if (qty && parseFloat(row.purchasePrice) > 0) {
        //有数量并有单价 算总价格
        row.productPrice = new Big(row.purchasePrice).times(qty).toNumber().toFixed(2);
      }else   if (qty && parseFloat(row.productPrice) > 0) {
        //有数量并有总价 算单价
        row.purchasePrice = new Big(row.productPrice).div(qty).toNumber().toFixed(2);
      }
    } else {
      row.checkQty = qty
    }
  }

  /**
   * 金额input
   * @param row
   * @param i
   * @param t 1=单价，2=总金额
   */
  inputPrice(row: any, i: number, t: number) {
    //数量
    let pQty=parseFloat(row.purchaseQty||0);
    // 单价
    let pPrice=parseFloat(row.purchasePrice||0);
    //总金额
    let price=parseFloat(row.productPrice||0);
    if (t == 1){
      //单价输入  有数量并有单价 算总价格
      if (pQty> 0 && pPrice > 0) {
        row.productPrice = new Big(pPrice).times(pQty).toNumber().toFixed(2);
      }
    }else {
      //总金额输入   有总价格并有单价  算 数量
      if (pPrice > 0 && price > 0) {
        row.purchaseQty = new Big(price).div(pPrice).toNumber().toFixed(2);
        let sum = 0;
        for (let j = 0; j < this.gateList.length; j++) {
          sum = new Big(row['gateNumber' + this.gateList[j].id] || 0).plus(sum).toNumber();
        }
        let c = new Big(sum).minus(row['gateNumber' + this.gateList[0].id] || 0).toNumber();
        //给第一个档口赋值 数量
        row['gateNumber' + this.gateList[0].id] = new Big(row.purchaseQty).minus(c).toNumber();
      }else  if (pQty > 0 &&price > 0){
          //总金额输入 有总价格并有数量  算单价
          row.purchasePrice = new Big(row.productPrice).div(row.purchaseQty).toNumber().toFixed(2);
        }
    }
  }

  /**
   * 设置商品 disabled
   */
  setProduct(productId: string, bol: boolean) {
    this.productList.forEach((item: any) => {
      if (item.id == productId) {
        item.disabled = bol;
      }
    })

  }

  /**
   * 获取商品下拉列表
   */
  getProductGetList() {
    productGetListApi(1).then(e => {
      this.productList = e;
      this.productList.forEach((item: any) => {
        item.disabled = false;
      })
    })
  }

  /**
   * 获取档口下拉列表
   */
  getGateList() {
    gateGetGateListApi().then(e => {
      this.gateList = e;
    })
  }

  /**
   * 供应商下拉
   */
  getSupplierList() {
    supplierListApi().then(e => {
      this.supplierList = e.map((item: any) => {
        return {
          label: item.supplierName,
          value: item.id
        }
      });
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("supplierId", {
        dicData: this.supplierList
      })
    })
  }
  /**
   * 采购员下拉
   */
  getPersonnelList() {
    personnelListApi().then(e => {
      this.personnelList = e.map((item: any) => {
        return {
          label: item.personnelName,
          value: item.id
        }
      });
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("personnelId", {
        dicData: this.personnelList
      })
    })
  }

  closeForm() {
    this.isCheck = false;
    this.tableForm.orderProductData = [];
  }

  initData() {
    this.isCheck = false;
    this.tableForm.orderProductData = [];

    this.getList();
    this.getProductGetList();
    this.getSupplierList();
    this.getPersonnelList();
  }

  getList() {
    const form: IOrderParams = deepCopy(this.queryParam);
    if (this.queryParam.purchaseTime && this.queryParam.purchaseTime.length) {
      form.purchaseStartTime = this.queryParam.purchaseTime[0]
      form.purchaseEndTime = this.queryParam.purchaseTime[1]
    } else {
      form.purchaseStartTime = ""
      form.purchaseEndTime = ""
    }
    if (this.queryParam.addTime && this.queryParam.addTime.length) {
      form.addStartTime = this.queryParam.addTime[0]
      form.addEndTime = this.queryParam.addTime[1]
    } else {
      form.addStartTime = ""
      form.addEndTime = ""
    }
    delete form.addTime
    delete form.purchaseTime
    orderQueryApi(form).then(e => {
      this.pageData.orderPrice = e.orderPrice || 0;
      this.pageData.settlementPrice = e.settlementPrice || 0;
      let {commonPage} = e;
      //@ts-ignore
      this.tableData = commonPage.list;
      this.tableTotal = commonPage.total
    })
  }

  inputChange(val:any){
    this.modelForm.settlementPrice=val.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1','')
    this.$forceUpdate()
  }

  created() {
    this.initData();
  }
}
